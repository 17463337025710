<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/1054990_rocket_spacecraft_spaceship_icon.png"
          class="h-20 ml-2 inline-block"
        />
        سفارش های من
      </h2>
      <p class="mt-3">
        از این قسمت می‌توانید سفارش های خود را ببینید و سفارش جدید ثبت کنید
      </p>
    </template>
    <template #default>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
        <Button
          :to="{ name: 'GroupOrder', params: { id: $store.state.user.id } }"
          color="cyan"
        >
          سفارش تبلیغ سراسری
        </Button>
        <Button
          :to="{ name: 'TargetOrder', params: { id: $store.state.user.id } }"
          color="cyan"
        >
          سفارش تبلیغ هدفمند
        </Button>
      </div>
      <h2 class="my-5 text-xl font-bold">آخرین سفارش ها</h2>
      <div
        class="
          grid grid-cols-2
          sm:grid-cols-2
          md:grid-cols-3
          xl:grid-cols-4
          gap-2
          lg:gap-4
          w-full
        "
      >
        <div
          v-for="order in orders"
          :key="order.id"
          class="bg-blueGray-100 border rounded-md border-t-4"
          :class="{
            'border-green-400': order.status == 'end',
            'border-cyan-400': order.status == 'running',
            'border-amber-400': order.status == 'wait',
            'border-red-400': order.status == 'incomplete',
          }"
        >
          <div
            class="text-center p-2 text-coolGray-900"
            :class="{
              'bg-green-400': order.status == 'end',
              'bg-cyan-400': order.status == 'running',
              'bg-amber-400': order.status == 'wait',
              'bg-red-400': order.status == 'incomplete',
            }"
          >
            {{ list_type[order.table] }}
          </div>
          <div class="flex px-3 py-2">
            <div class="flex-auto">شناسه</div>
            <div class="flex-auto text-left">{{ order.id }}</div>
          </div>
          <div class="flex px-3 py-2">
            <div class="flex-auto">وضعیت</div>
            <div class="flex-auto text-left">
              {{ list_status[order.status] }}
            </div>
          </div>
          <div class="flex gap-2 p-2 w-full">
            <Button
              :to="{
                name: { ad_group: 'GroupOrderEdit', ad_target: 'TargetOrderEdit' }[
                  order.table
                ],
                params: { id: $store.state.user.id, adId: order.id },
              }"
              v-if="order.status != 'end'"
              :color="order.status == 'incomplete' ? 'red' : 'blueGray'"
              class="h-10"
            >
              {{ order.status == "incomplete" ? "تکمیل" : "ویرایش" }}
            </Button>
            <Button
              :to="{
                name: { ad_group: 'GroupOrderInfo', ad_target: 'TargetOrderInfo' }[
                  order.table
                ],
                params: { id: $store.state.user.id, adId: order.id },
              }"
              class="h-10"
              color="blueGray"
            >
              جزئیات
            </Button>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
// import MnrText from "@/components/mnr/MnrText.vue";
// import MnrPassword from "@/components/mnr/MnrPassword.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    // MnrText,
    // MnrPassword,
    Button,
    BodySimple,
  },
  data() {
    return {
      test: "blueGray",
      firstLoading: true,
      orders: [],
      errors: [],
      disabaledForm: false,
      list_status: {
        end: "تمام شده",
        running: "در حال اجرا",
        wait: "در انتظار",
        incomplete: "نیاز به تکمیل",
      },
      list_type: { ad_group: "🚀 تبلیغات سراسری", ad_target: "🎯 تبلیغات هدفمند" },
    };
  },
  mounted() {
    let $this = this;
    $this.errors = {};
    $this.disabaledForm = true;

    $this.$axios
      .get("/api/ad-orders", {
        params: { id: $this.$store.state.user.id },
      })
      .then(function (response) {
        // console.log(response);
        $this.orders = response.data;
      })
      .catch(function (error) {
        $this.errors = error.data;
      })
      .finally(function () {
        $this.firstLoading = false;
      });
  },
};
</script>
